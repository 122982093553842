a {
    color: #CBE86B;
}

.Header-iconspace {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: calc(10px + 1vmin);
}

.Header-icon {
    margin: 0 2vmin;
}

.Header {
    padding: calc(6vh - (10px + 2vmin)) 0;
    background-color: #1C140D;
    min-width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: calc(10px + 2vmin);
}