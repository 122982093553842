.App {
  max-height: 100vh;
}

.App-link {
  color: #09d3ac;
}

.App-body {
  background-color: #FFF;
  color: #1C140D;
  min-height: 92vh;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.App-margin {
  min-width: 150px;
  height: 1px;
}

.App-content {
  min-width: 75vw;
}

.App-large {
  color: #1C140D;
  font-size: calc(50px + 10vmin);
}

.App-small {
  text-align: center;
  color: #1C140D;
  font-size: calc(10px + 1vmin);
}